import { Component, Input, OnChanges, Output, EventEmitter, ViewChild, HostBinding } from '@angular/core';
import { PaymentsAuthService } from '@services/utils/auth-services/payments-auth.service';
import { SleepService, GlobalsService, LookupService, PaymentsService, InvoicesService, UtilsService, CreditCardService } from '@services';
import { SlickConfirmDialogComponent, SlickConfirmDialogResults, SlickScreenBlockerService, SlickToastService } from "@slick-components";
import { IDropdownModel, ICustomerModel, CreditCardProcessPaymentModel, ICreditCardPaymentTypeModel, ICreditCardPaymentApprovedModel, IEmailerModel, IInvoiceListModel, DropdownModel, IAppliedPaymentModel, IPaymentModel, AppliedPaymentModel, IACHPaymentApprovedModel} from '@models';
import { ProcessPaymentDialogComponent } from '@components/process-payment-dialog/process-payment-dialog.module';
import { EmailerDialogComponent } from '@components/emailer-dialog';
import Swal from 'sweetalert2';
import { RefundPaymentDialogComponent } from '@shared-components/refund-payment-dialog/refund-payment-dialog.component';

@Component({
	selector: 'payment-edit',
	templateUrl: './payment-edit.component.html',
	styleUrls: ['payment-edit.component.scss'],
	providers: [ PaymentsAuthService, CreditCardService ]
})
export class PaymentEditComponent implements OnChanges {
	@HostBinding('style.flex') flex = '1';

	@Input() isDialog: boolean;
	@Input() paymentModel: IPaymentModel;
	@Output() onSave = new EventEmitter<IPaymentModel>();
	@Output() onVoidOrRefund = new EventEmitter<IPaymentModel>();
	@Output() onCancel = new EventEmitter<void>();

	@ViewChild("processPaymentDialogRef") processPaymentDialogRef: ProcessPaymentDialogComponent;
	@ViewChild("emailerDialogRef") emailerDialogRef: EmailerDialogComponent;
	@ViewChild("refundPaymentDialogRef") refundPaymentDialogRef: RefundPaymentDialogComponent;
	@ViewChild("confirmVoidRef") confirmVoid: SlickConfirmDialogComponent;
	@ViewChild("confirmRefundRef") confirmRefundRef: SlickConfirmDialogComponent;


	spinnerStatus: string = null;
	isSubmitted = false;
	emailerModel: IEmailerModel;
	tabKey: string;
	paymentTypes: IDropdownModel[];
	customerInvoices: IInvoiceListModel[];
	invoicesExpanded: boolean = false
	allowAmericanExpress: boolean = GlobalsService.company.allowAmericanExpress;

	isQuickbooksEnabled = GlobalsService.isQuickbooksEnabled;
	isBusinessCentralEnabled = GlobalsService.isBusinessCentralEnabled;
	isBambora: boolean = GlobalsService.company.useBambora;
	adminEdit: boolean = false;
	adminUnlock: boolean = GlobalsService.checkPermission("Payments", "AdminUnlock");
	canVoidPayments: boolean = GlobalsService.checkPermission("Payments", "CanVoidPayments");
	canRefundPayments: boolean = GlobalsService.checkPermission("Payments", "CanRefundPayments");

	parentMaxHeight = (GlobalsService.userInfo.layoutSettings.paymentsLayout === 'grid-full-screen') ? "calc(100vh - 140px)" : "calc(100vh - 80px)"
	tabMaxHeight = (GlobalsService.userInfo.layoutSettings.paymentsLayout === 'grid-full-screen') ? "calc(100vh - 260px)" : "calc(100vh - 188px)"
	tabIndex: number = 0;

	constructor(
		private creditCardService: CreditCardService,
		public paymentsAuthService: PaymentsAuthService,
		private paymentsService: PaymentsService,
		private invoicesService: InvoicesService,
		private slickToastService: SlickToastService,

		private slickScreenBlockerService: SlickScreenBlockerService) {

		this.paymentTypes = [];
		this.paymentTypes.push(new DropdownModel("Cash", "Cash"));
		this.paymentTypes.push(new DropdownModel("Check", "Check"));
		this.paymentTypes.push(new DropdownModel("American Express", "American Express"));
		this.paymentTypes.push(new DropdownModel("Discover", "Discover"));
		this.paymentTypes.push(new DropdownModel("Mastercard", "Mastercard"));
		this.paymentTypes.push(new DropdownModel("Visa", "Visa"));
		this.paymentTypes.push(new DropdownModel("e-transfer", "e-transfer"));
		this.paymentTypes.push(new DropdownModel("ACH/EFT", "ACH/EFT"));
		this.paymentTypes.push(new DropdownModel("Wire Transfer", "Wire Transfer"));
		this.paymentTypes.push(new DropdownModel("Other", "Other"));
	}

	ngOnChanges() {
		this.isSubmitted = false;
		this.spinnerStatus = "reset";
		this.invoicesExpanded = false
		this.adminEdit = false;
		this.tabIndex = 0;

		if (this.paymentModel) {
			this.paymentModel.invoiceTotals = 0;
			this.paymentModel.appliedPayments?.forEach(x => this.recalcAppliedPayment(x));
			this.paymentModel.paymentDate = this.formatDate(this.paymentModel.paymentDate.toString());
			if (this.paymentModel.appliedPayments) 
				this.recalcAppliedAmount();
			
			this.getCustomerInvoices();
		}
	}

	copyAppliedAmountToAmount() {
		this.paymentModel.amount = this.paymentModel.appliedAmount;
		this.recalcAppliedAmount();
	}

	onTabChanged(tabKey: string) {
		this.tabKey = tabKey;
	}

	onCustomerModelChange(customerModel: ICustomerModel) {
		this.paymentModel.customer = customerModel;
		this.getCustomerInvoices();
	}

	async generatePaymentPdf() {
		if (!this.paymentModel.paymentId) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please save the payment before printing",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		const w = window.open('', '_blank');
		w.document.write('Generating pdf...');
		const url = await this.paymentsService.generatePaymentReceiptPDF(this.paymentModel.paymentId);
		w.location.href = url;
	}

	async emailPayment() {
		this.slickScreenBlockerService.block();

		try {
			const emailerModel = await this.paymentsService.preparePaymentReceiptEmail(this.paymentModel);
	
			this.emailerDialogRef.showDialog(emailerModel);
		}
		finally {
			this.slickScreenBlockerService.unblock();
		}
	}

	

	async openCreditCardDialog() {
		//if (!this.paymentModel.customer) {
		//	Swal.fire({
		//		icon: 'warning',
		//		title: 'Oops...',
		//		text: "Please enter a customer",
		//		confirmButtonColor: '#007bff',
		//		heightAuto: false
		//	});
		//	return;
		//}

		this.isSubmitted = true;
		if (!this.paymentModel.customer)
			return false;

		if (!this.paymentModel.paymentType)
			return false;

		await this.savePayment();


		const creditCardProcessPaymentModel = new CreditCardProcessPaymentModel();
		creditCardProcessPaymentModel.paymentId = this.paymentModel.paymentId;
		creditCardProcessPaymentModel.amount = this.paymentModel.amount ?? 0;
		creditCardProcessPaymentModel.paymentReferenceCode = this.paymentModel.referenceNumber;
		this.processPaymentDialogRef.openDialog(this.paymentModel.customer, null, this.paymentModel.paymentId, this.paymentModel.amount ?? 0);
	}

	onPaymentTypesChanged(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel[]) {
		const defaultCard = creditCardPaymentTypeModel.find(x => x.isDefault === true);

		if (defaultCard)
			this.paymentModel.customer.cardOnFile = defaultCard.displayText;
	}

	onApproved(paymentApprovedModel: ICreditCardPaymentApprovedModel) {
		this.paymentModel = paymentApprovedModel.paymentModel;
		this.onSave.emit(paymentApprovedModel.paymentModel);
	}

	onACHApproved(paymentApprovedModel: IACHPaymentApprovedModel) {
		this.paymentModel = paymentApprovedModel.paymentModel;
		this.onSave.emit(paymentApprovedModel.paymentModel);
	}

	validate(): boolean {
		if (!this.paymentModel.customer)
			return false;

		if (!this.paymentModel.paymentType)
			return false;

		if (!this.paymentModel.paymentDate)
			return false;

		if (this.paymentModel.amount === null)
			return false;
	}

	async savePayment() {
		try {
			this.isSubmitted = true;

			if (this.validate() === false) {
				this.spinnerStatus = "error";
				return;
			}

			this.spinnerStatus = "spin";

			await SleepService.sleep(500);

			if (this.paymentModel.syncToQuickbooks && this.isQuickbooksEnabled) {
				if (!this.paymentModel.customer.quickbooksId) {
					await Swal.fire({
						icon: 'warning',
						title: 'Oops',
						text: 'Please sync the customer to QuickBooks before saving.',
						confirmButtonColor: '#007bff',
						width: '28em',
						heightAuto: false
					});
					this.spinnerStatus = "error";
					return;
				}
			}

			if (this.paymentModel.syncToBusinessCentral) {
				if (!this.paymentModel.customer.businessCentralId) {
					await Swal.fire({
						icon: 'warning',
						title: 'Oops',
						text: 'Please sync the customer to Business Central before saving.',
						confirmButtonColor: '#007bff',
						width: '28em',
						heightAuto: false
					});
					this.spinnerStatus = "error";
					return;
				}
			}

			this.paymentModel = await this.paymentsService.updatePayment(this.paymentModel);

			//seperating this out to fix qb errors being thrown and causing the payment model to not be returned.
			if ((this.isQuickbooksEnabled && this.paymentModel.syncToQuickbooks === true) || (this.isBusinessCentralEnabled && this.paymentModel.syncToBusinessCentral === true)) {
				this.paymentModel = await this.paymentsService.syncToQuickbooks(this.paymentModel.paymentId);
			}

			if (this.onSave)
				this.onSave.emit(this.paymentModel);

			this.spinnerStatus = "ok";
		}
		catch (err) {
			this.spinnerStatus = "error";
		}
	}

	formatDate(dateString) {
		// Create a Date object from the given string
		const date = new Date(dateString);

		// Extract the year, month, and day
		const year = date.getFullYear();
		const month = date.getMonth(); // getMonth() is zero-based
		const day = date.getDate();

		// Return a new Date object containing only the date part
		return new Date(year, month, day);
	}


	cancelPayment() {
		if (this.onCancel)
			this.onCancel.emit();
	}

	private async getCustomerInvoices() {
		this.customerInvoices = null;

		if (!this.paymentModel.customer || this.paymentModel.customer.customerId === 0) {
			this.customerInvoices = [];
			return;
		}

		this.customerInvoices = await this.invoicesService.getInvoicesByCustomer(this.paymentModel.customer.customerId, true);

		// Remove any invoices that are already set as applied payments
		this.paymentModel.appliedPayments?.forEach(ap => {
			this.customerInvoices = this.customerInvoices.filter(x => x.invoiceId !== ap.invoiceId);
		});

		//const invoicesThatMatchAvailable = this.customerInvoices.filter(x => x.outstandingBalance === this.paymentModel.unappliedAmount);
		//if (invoicesThatMatchAvailable.length > 0) {
		//	const temp = this.customerInvoices.filter(x => x.outstandingBalance !== this.paymentModel.unappliedAmount);
		//	this.customerInvoices = invoicesThatMatchAvailable.concat(temp);
		//}
	}

	onAppliedAmountChanged(appliedPayment: IAppliedPaymentModel) {
		this.recalcAppliedPayment(appliedPayment);
		this.recalcAppliedAmount();
	}

	async removeAppliedPayment(appliedPayment: IAppliedPaymentModel) {
		this.paymentModel.appliedPayments = this.paymentModel.appliedPayments?.filter(x => x.uuid !== appliedPayment.uuid);
		await this.recalcAppliedAmount();
		this.getCustomerInvoices();
	}

	async addAppliedPayment(invoice: IInvoiceListModel) {
		const newAppliedPayment = new AppliedPaymentModel();
		newAppliedPayment.uuid = UtilsService.newGuid();
		newAppliedPayment.invoiceId = invoice.invoiceId;
		newAppliedPayment.invoice = await this.invoicesService.getInvoice(invoice.invoiceId);
		
		
		
		if (this.paymentModel.unappliedAmount > invoice.outstandingBalance || this.paymentModel.amount === 0)
			newAppliedPayment.appliedAmount = invoice.outstandingBalance;
		else
			newAppliedPayment.appliedAmount = this.paymentModel.unappliedAmount;
		newAppliedPayment.invoice.appliedPayments.push(newAppliedPayment);

		this.paymentModel.appliedPayments.push(newAppliedPayment);

		this.recalcAppliedPayment(newAppliedPayment);
		this.recalcAppliedAmount();

		this.customerInvoices = this.customerInvoices.filter(x => x.invoiceId !== invoice.invoiceId);
	}

	recalcInvoiceTotals(appliedPaymentModel: IAppliedPaymentModel) {
		this.paymentModel.invoiceTotals += appliedPaymentModel.invoice.outstandingBalance;
	}

	private recalcAppliedPayment(appliedPayment: IAppliedPaymentModel) {
		appliedPayment.appliedAmount = parseFloat(appliedPayment.appliedAmount.toString());
		appliedPayment.appliedAmount = UtilsService.round(appliedPayment.appliedAmount);

		// There may be other applied payments from other payment records.
		// We need to find THIS applied payment, update it and recalc all the applied payment
		// to get the outstanding balance
		const invoiceAppliedPayment = appliedPayment.invoice.appliedPayments.find(x => x.uuid === appliedPayment.uuid);
		invoiceAppliedPayment.appliedAmount = appliedPayment.appliedAmount;
		const totalAppliedAmount = appliedPayment.invoice.appliedPayments.reduce((appliedAmt, ap) => (appliedAmt += ap.appliedAmount), 0);
		appliedPayment.invoice.outstandingBalance = appliedPayment.invoice.invoiceTotal - totalAppliedAmount;

		const additionalAppliedPayments = appliedPayment.invoice.appliedPayments.filter(x => x.uuid !== appliedPayment.uuid);

		appliedPayment.invoice.appliedPaymentsTotal = additionalAppliedPayments.reduce((appliedAmt, ap) => (appliedAmt += ap.appliedAmount), 0);

		this.recalcInvoiceTotals(appliedPayment);
		
	}

	private async recalcAppliedAmount() {
		await SleepService.sleep();

		if (!this.paymentModel)
			return;

		this.paymentModel.appliedAmount = this.paymentModel.appliedPayments.reduce((appliedAmount, ap) => (appliedAmount += ap.appliedAmount), 0);
		this.paymentModel.unappliedAmount = this.paymentModel.amount - this.paymentModel.appliedAmount;
	}


	async openRefundPaymentDialog() {
		
		const refundedPaymentModel = await this.refundPaymentDialogRef.showDialog();

		//if there is any applied payments for this payment, we need to remove them all if we refund them.
		if (this.paymentModel.appliedPayments.length > 0) {
			const confirm = await this.confirmRefundRef.confirm();
			if (confirm !== SlickConfirmDialogResults.Ok)
				return
		}

		if (refundedPaymentModel) {
			this.paymentModel = refundedPaymentModel;
			this.onVoidOrRefund.emit(this.paymentModel);
        }
		
	}

	async voidPayment() {
		const confirm = await this.confirmVoid.confirm();
		if (confirm === SlickConfirmDialogResults.Ok) {
			try {
				this.slickScreenBlockerService.block();
				const returnModel = await this.creditCardService.voidPayment(this.paymentModel);
				if (returnModel) {
					this.paymentModel = returnModel;
					this.slickToastService.showSuccess("Payment voided successfully")
					this.paymentModel.canVoid = false;
					this.onVoidOrRefund.emit(returnModel);
				}
			}
			finally {
				this.slickScreenBlockerService.unblock();
			}
		}
	}
}
