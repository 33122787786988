import { GlobalsService } from "../../services";

export interface ICreditCardProcessPaymentModel {
	customerId: number;
	invoiceId?: number;
	invoiceIds: number[];
	paymentId?: number;
	creditCardPaymentTypeId: number;
	amount: number;
	cardCode: string;
	invoiceNumber: string;
	poNumber: string;
	paymentReferenceCode: string;
	isOnlinePayment: boolean;
	isDownPayment: boolean;
	isSkeditPay: boolean;
	syncToQuickbooks: boolean;
	notes: string;
	includeCCFee: boolean;
}

export class CreditCardProcessPaymentModel implements ICreditCardProcessPaymentModel {
	constructor() {
		this.isSkeditPay = GlobalsService.company.useSkeditPay;
    }
	customerId: number;
	invoiceId: number;
	invoiceIds: number[];
	paymentId: number;
	creditCardPaymentTypeId: number;
	amount: number;
	cardCode: string;
	invoiceNumber: string;
	poNumber: string;
	paymentReferenceCode: string;
	isOnlinePayment: boolean;
	isDownPayment: boolean;
	isSkeditPay: boolean;
	syncToQuickbooks: boolean;
	notes: string;
	includeCCFee: boolean;
}